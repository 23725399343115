<template>
    <FooterMobile />

    <footer class="hide-mobile show-tablet">
        <div class="container">
            <div class="row">
                <div class="col-md-4 col-sm-3 text-m-center">
                    <router-link to="/">
                        <img :src="logo" class="footer-logo" alt="" />
                    </router-link>
                    <p class="gray-color">
                        Freefone is a secure personal phone app designed to provide users with spam protection, privacy
                        protection and identity theft protection.
                    </p>
                </div>
                <div class="col-md-2 hide-tablet"></div>
                <div class="col-md-2 col-sm-3">
                    <div class="footer-list-title">Get started</div>
                    <ul>
                        <li>
                            <a href="https://portal.freefone.app/login">Get Freefone for Iphone</a>
                        </li>
                        <li>
                            <a href="https://portal.freefone.app/login">Get Freefone for Android</a>
                        </li>
                        <li>
                            <a href="#compare-section">Plan & Pricing</a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-2 col-sm-3">
                    <div class="footer-list-title">Legal</div>
                    <ul>
                        <li><router-link to="/terms-of-use">Terms of Use</router-link></li>
                        <li>
                            <router-link to="/privacy">Privacy Policy</router-link>
                        </li>
                        <li>
                            <router-link to="/delete_number">Delete your FreeFone number</router-link>
                        </li>
                    </ul>
                </div>
                <div class="col-md-2 col-sm-3">
                    <div class="footer-list-title">About</div>
                    <ul>
                        <li>
                            <a href="#contactUs">Contact Us</a>
                        </li>
                        <li>
                            <a href="#" v-scroll-to="'#whatWeDoSection'">About Freefone</a>
                        </li>
                        <li>
                            <a href="mailto:support@freefone.app">Support</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright">
            <div class="container">
                <div class="d-flex justify-content-between">
                    <div class="gray-color">© Copyright {{ currentYear }} Freefone. All Rights Reserved.</div>
                    <div class="gray-color">User Agreement</div>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
import FooterMobile from './../../components/layouts/FooterMobile.vue'

export default {
    data() {
        return {
            logo: require('./../../assets/images/layouts/Header/logo.svg'),
        }
    },
    components: {
        FooterMobile,
    },
    computed: {
        currentYear () {
            return new Date().getFullYear()
        }
    },
    methods: {},
}
</script>
<style>
@import './../../assets/styles/components/layouts/footer.css';
</style>
